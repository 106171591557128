.navcell {
    width: 250px;
    height: 250px;
    margin: 10px;
    padding: 15px;
    border-radius: 15px;
    opacity: .85;
    text-align: center;
    vertical-align: middle;
    font-weight: bolder;
    font-style: italic;
    position: absolute;
    display: table;
    transition-property: width height, left, top, opacity, background-color;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    overflow: hidden;
}

.navcell_expanded {
    z-index: 100;
    width: 970px;
    height: 500px;
    opacity: 1;
    overflow: hidden;
    background-color: rgb(0,0,45);
}



.heading {
    display: table-cell;
    vertical-align:  middle;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.heading_transparent {
    opacity: 0;
    transition-delay: .1s;
}

.heading:hover {
    color: rgb(255,0,0);
}

.content {
    position: absolute;
    left: 15px;
    top: 15px;
    display: table-cell;
    vertical-align:  middle;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    font-style: normal;
    font-weight: normal;
}

.content_transparent {
    z-index: -10;
    transition-duration: 0.4s;
    opacity: 0;
}