div {
  margin: 0px;
}
body {
  margin: 0px;
}

#root {
  margin: 0px;
}
.App {
  position: relative;
  margin: 0px;
  height: 95vh;
  size: 0;
}
.FillWidth {
  margin: 0px;
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}

.container {
  max-width: 920px;
  margin: 25px auto;
  position: relative;
}

.scroll_y {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.scroll_y::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.fixedheight {
    text-align: left;
    height: 500px;
    width: 970px;
}